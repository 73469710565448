import {
  faChevronLeft,
  faChevronRight,
  faFileCsv,
  faCircle,
  faClock,
} from "@fortawesome/free-solid-svg-icons";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState, useCallback } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  Container,
  Table,
  Tooltip,
} from "reactstrap";
import Button from "reactstrap/lib/Button";
import { reportsApi } from "../../../services/reportsServices";
import { payrollWeekApprovalApi } from "../../../services/payrollWeekApprovalServices";
import moment from "moment";
import { useAuth } from "../../../providers/authProvider";
import InformationModal from "../../../components/InformationModal";
import ConfirmationModal from "../../../components/ConfirmationModal";
import Loader from "../../../components/Loader";
import { useNavigate } from "react-router-dom";
import { onExportCSV, utils } from "../../../utils/utils";
import TooltipItem from "../../../components/TooltipItem";

const dateFormat = "MM/DD";

const otherTimeTypes = ["PTO", "SHOP", "JURY", "BER", "LUNCH"];
const SHOP_HOURS = "SHOP";
const LUNCH_HOURS = "LUNCH";

const getDateLabel = (date, increment = 0) => {
  const incrementDate = moment(date).add(increment, "day");
  return `${incrementDate.format("dd")} ${incrementDate.format(dateFormat)}`;
};

const PayrollReport = () => {
  const [authContext, setAuthContext] = useAuth();
  const navigate = useNavigate();
  const [tooltipOpen, setTooltipOpen] = useState(null);
  const [reloadPayrollWeekApproval, setReloadPayrollWeekApproval] =
    useState(false);

  const toggleTooltip = (index) => {
    setTooltipOpen((prevState) => (prevState === index ? null : index));
  };

  const [data, setData] = useState([]);
  const [rawData, setRawData] = useState([]);
  const [loading, setLoading] = useState();
  const [payrollApprovalLoading, setPayrollApprovalLoading] = useState();
  const [payrollWeekApproved, setPayrollWeekApproved] = useState();
  const [monday, setMonday] = useState(moment().startOf("isoWeek"));

  const initConfirmationModal = {
    isOpen: false,
    onSubmit: null,
    onClose: null,
    title: "",
    body: "",
  };

  const [confirmationModal, setConfirmationModal] = useState(
    initConfirmationModal
  );

  const [informationModal, setInformationModal] = useState({
    isOpen: false,
    title: "",
    body: "",
  });

  const previousMonday = () => setMonday(moment(monday).add(-1, "week"));

  const nextMonday = () => setMonday(moment(monday).add(1, "week"));

  const getSunday = () => moment(monday).endOf("isoWeek");

  const getWeekDates = useCallback(() => {
    const dates = [];
    let currentDate = moment(monday);
    while (dates.length < 7) {
      dates.push(currentDate);
      currentDate = moment(currentDate).add(1, "day");
    }
    return dates;
  }, [monday]);

  const getWeekDayValues = useCallback(
    (d) => {
      const weekDates = getWeekDates();
      const weekDayValues = {};
      weekDates.forEach((w) => {
        const key = getDateLabel(w);
        const dateData = d.payRoll.find(
          (e) => e.date === moment(w).format("YYYY-MM-DD")
        );
        let value = "-";
        if (dateData) {
          const reg = parseFloat(dateData.REG.toFixed(2));
          const ot = parseFloat(dateData.OT.toFixed(2));
          const double = parseFloat(dateData.DOUBLE.toFixed(2));
          const ptoReg = parseFloat(dateData.PTO.reg.toFixed(2));
          const ptoOt = parseFloat(dateData.PTO.ot.toFixed(2));
          const juryReg = parseFloat(dateData.JURY.reg.toFixed(2));
          const juryOt = parseFloat(dateData.JURY.ot.toFixed(2));
          const berReg = parseFloat(dateData.BER.reg.toFixed(2));
          const berOt = parseFloat(dateData.BER.ot.toFixed(2));
          const lunchReg = parseFloat(dateData.LUNCH.reg.toFixed(2));
          const lunchOt = parseFloat(dateData.LUNCH.ot.toFixed(2));
          const total = (
            reg +
            ot +
            double +
            ptoReg +
            ptoOt +
            juryReg +
            juryOt +
            berReg +
            berOt +
            lunchReg +
            lunchOt
          ).toFixed(2);
          value = total;
        }
        weekDayValues[key] = value;
      });
      return weekDayValues;
    },
    [getWeekDates]
  );

  const getResultDataFormatted = useCallback(
    (data) => {
      return data.map((d) => {
        const reg = parseFloat(d.weeklyPayroll.REG.toFixed(2));
        const ot = parseFloat(d.weeklyPayroll.OT.toFixed(2));
        const ptoReg = parseFloat(d.weeklyPayroll.PTO.reg.toFixed(2));
        const ptoOt = parseFloat(d.weeklyPayroll.PTO.ot.toFixed(2));
        const shopReg = parseFloat(d.weeklyPayroll.SHOP.reg.toFixed(2));
        const shopOt = parseFloat(d.weeklyPayroll.SHOP.ot.toFixed(2));
        const juryReg = parseFloat(d.weeklyPayroll.JURY.reg.toFixed(2));
        const juryOt = parseFloat(d.weeklyPayroll.JURY.ot.toFixed(2));
        const berReg = parseFloat(d.weeklyPayroll.BER.reg.toFixed(2));
        const berOt = parseFloat(d.weeklyPayroll.BER.ot.toFixed(2));
        const double = parseFloat(d.weeklyPayroll.DOUBLE.toFixed(2));
        const lunchReg = parseFloat(d.weeklyPayroll.LUNCH.reg.toFixed(2));
        const lunchOt = parseFloat(d.weeklyPayroll.LUNCH.ot.toFixed(2));
        const regApproval = parseFloat(d.weeklyPayroll.REG_APPROVAL.toFixed(2));
        const otApproval = parseFloat(d.weeklyPayroll.OT_APPROVAL.toFixed(2));
        const ptoApproval = parseFloat(d.weeklyPayroll.PTO_APPROVAL.toFixed(2));
        const shopApproval = parseFloat(
          d.weeklyPayroll.SHOP_APPROVAL.toFixed(2)
        );
        const juryApproval = parseFloat(
          d.weeklyPayroll.JURY_APPROVAL.toFixed(2)
        );
        const berApproval = parseFloat(d.weeklyPayroll.BER_APPROVAL.toFixed(2));
        const doubleApproval = parseFloat(
          d.weeklyPayroll.DOUBLE_APPROVAL.toFixed(2)
        );
        const lunchApproval = parseFloat(
          d.weeklyPayroll.LUNCH_APPROVAL.toFixed(2)
        );
        const total = (
          reg +
          ot +
          ptoReg +
          ptoOt +
          juryReg +
          juryOt +
          berReg +
          berOt +
          double +
          lunchReg +
          lunchOt
        ).toFixed(2);
        const totalApproved = (
          regApproval +
          otApproval +
          ptoApproval +
          shopApproval +
          juryApproval +
          berApproval +
          doubleApproval +
          lunchApproval
        ).toFixed(2);

        return {
          employeeId: d.id,
          employee: d.firstName + " " + d.lastName,
          approvedBy: d.approvedBy,
          ...getWeekDayValues(d),
          regular: reg.toFixed(2),
          overtime: ot.toFixed(2),
          PTO: (ptoReg + ptoOt).toFixed(2),
          SHOP: (shopReg + shopOt).toFixed(2),
          JURY: (juryReg + juryOt).toFixed(2),
          BER: (berReg + berOt).toFixed(2),
          LUNCH: (lunchReg + lunchOt).toFixed(2),
          double: double.toFixed(2),
          total,
          totalApproved,
        };
      });
    },
    [getWeekDayValues]
  );

  useEffect(() => {
    const serviceLocation = authContext.serviceLocations.find(
      (sl) => sl.id === authContext.serviceLocationId
    );
    const payrollWeekApproved = serviceLocation?.payrollWeekApprovals?.find(
      (item) => item.weekStart === monday.format("YYYY-MM-DD")
    );
    setPayrollWeekApproved(payrollWeekApproved);
  }, [monday, authContext]);

  useEffect(() => {
    const getPayrollReport = async () => {
      try {
        setLoading(true);
        const result = await reportsApi.payrollReport({
          weekStart: monday.format("YYYY-MM-DD"),
          serviceLocationId: authContext.serviceLocationId,
        });
        setLoading(false);
        const data = getResultDataFormatted(result);
        setRawData(result);
        setData(data);
      } catch (err) {
        setLoading(false);
        setInformationModal({
          isOpen: true,
          title: "Error",
          body:
            err?.response?.data[0].msg ||
            "There was an error with your request.",
        });
      }
    };
    getPayrollReport();

    if (reloadPayrollWeekApproval) {
      getPayrollReport();
      setReloadPayrollWeekApproval(false);
    }
  }, [
    monday,
    getResultDataFormatted,
    authContext.serviceLocationId,
    reloadPayrollWeekApproval,
  ]);

  const onSeeWODetails = (workOrderId) =>
    navigate(`/work-orders/${workOrderId}/details`);

  const onSeeCustomerDetails = (customerId) =>
    navigate(`/customers/${customerId}/details`);

  const onSeeDateDetails = (entry, date) => {
    const employeeData = rawData.find((d) => d.id === entry.employeeId);
    const dateData = employeeData.payRoll.find((p) =>
      moment(p.date).isSame(date, "date")
    );

    if (employeeData) {
      employeeData.workTimesRaw.forEach((workTime) => {
        if (workTime.employeeWorkDay.isNewTimeEntry) {
          const { hours, overtimeHours } = utils.calculateHours(
            workTime.startTime,
            workTime.endTime,
            dateData.isWeekend,
            dateData.isHoliday
          );
          workTime.hours = hours;
          workTime.overtimeHours = overtimeHours;
        }
      });
    }

    const dateWorkTimes = employeeData.workTimesRaw.filter((p) =>
      moment(p.employeeWorkDay.date).isSame(date, "date")
    );
    const dateOtherTimes = employeeData.otherTimesRaw.filter((p) =>
      moment(p.date).isSame(date, "date")
    );

    const totalHoursDay = (
      dateWorkTimes.reduce((total, entry) => {
        return total + entry.hours + entry.overtimeHours;
      }, 0) +
      dateOtherTimes.reduce((total, entry) => {
        return total + entry.hours + entry.overtimeHours;
      }, 0)
    ).toFixed(2);

    const totalOtherTimeDay = dateOtherTimes.reduce((total, entry) => {
      if (entry.otherTimeType.name !== SHOP_HOURS) {
        return total + entry.hours + entry.overtimeHours;
      }
      return total;
    }, 0);

    const { newTimeWorkOrders, legacyTimeWorkOrders } =
      dateData?.workOrders.reduce(
        (acc, workOrder) => {
          if (workOrder.isNewTimeEntry) {
            acc.newTimeWorkOrders.push(workOrder);
          } else {
            acc.legacyTimeWorkOrders.push(workOrder);
          }
          return acc;
        },
        { newTimeWorkOrders: [], legacyTimeWorkOrders: [] }
      );

    const otherTimeSummary = otherTimeTypes.reduce((acc, otherTimeType) => {
      const timeData = dateData[otherTimeType];
      if (timeData && (timeData.reg !== 0 || timeData.ot !== 0)) {
        acc[otherTimeType] = timeData;
      }
      return acc;
    }, {});

    const WorkOrderRow = ({ workOrder, index, workOrderWorkTimes }) => {
      return (
        <tr>
          <td>
            <span
              key={index}
              onClick={() => onSeeWODetails(workOrder.id)}
              className="cursor-pointer text-link"
            >
              {workOrder.jobNo}
            </span>
          </td>
          <td>
            <span
              key={workOrder.customerId}
              onClick={() => onSeeCustomerDetails(workOrder.customerId)}
              className="cursor-pointer text-link"
            >
              {workOrder.customerWorkOrder.customerName}
            </span>
          </td>
          {!workOrder.isNewTimeEntry ? (
            <>
              <td>{workOrderWorkTimes.regular.toFixed(2)}</td>
              <td>{workOrderWorkTimes.overtime.toFixed(2)}</td>
            </>
          ) : (
            <td>
              <FontAwesomeIcon icon={faClock} />
              <span className="ml-1">
                {utils.convertTo12HourFormat(workOrderWorkTimes.startTime)}
              </span>
              <span className="mx-1">/</span>
              <FontAwesomeIcon icon={faClock} color="gray" />
              <span className="ml-1">
                {utils.convertTo12HourFormat(workOrderWorkTimes.endTime)}
              </span>
            </td>
          )}
        </tr>
      );
    };

    setInformationModal({
      isOpen: true,
      title: entry.employee,
      rawBody: true,
      size: "xl",
      body: (
        <>
          <h4 className="mb-4">
            {date.format(dateFormat)} - {totalHoursDay} Total hours
          </h4>
          <Table className="border rounder mb-3" size="small">
            <thead className="small text-muted">
              <tr>
                <th>Regular Time</th>
                <th>Overtime</th>
                {dateData.IS_CALIFORNIA_PAYROLL ? <th>Double Time</th> : null}
                <th>Other</th>
              </tr>
            </thead>
            <tbody>
              {dateData ? (
                <tr>
                  <td>{dateData.REG.toFixed(2)}</td>
                  <td>{dateData.OT.toFixed(2)}</td>
                  {dateData.IS_CALIFORNIA_PAYROLL ? (
                    <td>{dateData.DOUBLE.toFixed(2)}</td>
                  ) : null}
                  <td>{totalOtherTimeDay.toFixed(2)}</td>
                </tr>
              ) : (
                <tr>
                  <td colSpan="3" className="text-center">
                    No data has been recorded
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
          <div className="mb-2">
            <span className="font-weight-bold">Time Details</span>
          </div>
          {newTimeWorkOrders && newTimeWorkOrders.length ? (
            <Table className="border rounded mb-3" size="small">
              <thead className="small text-muted">
                <tr>
                  <th>Work Order</th>
                  <th>Customer</th>
                  <th>Time Entered</th>
                </tr>
              </thead>
              <tbody>
                {newTimeWorkOrders.map((workOrder, index) => {
                  const workOrderWorkTimes = dateWorkTimes.find(
                    (workTime) =>
                      workTime.employeeWorkDay.workOrderEmployee.workOrderTrip
                        .workOrderId === workOrder.id
                  );
                  return (
                    <WorkOrderRow
                      key={index}
                      workOrder={workOrder}
                      index={index}
                      workOrderWorkTimes={workOrderWorkTimes}
                    />
                  );
                })}
              </tbody>
            </Table>
          ) : null}
          {(legacyTimeWorkOrders && legacyTimeWorkOrders.length) ||
          Object.keys(otherTimeSummary).length ? (
            <Table className="border rounded mb-0" size="small">
              <thead className="small text-muted">
                <tr>
                  <th>Work Order</th>
                  <th>Customer</th>
                  <th>Regular</th>
                  <th>Overtime</th>
                </tr>
              </thead>
              <tbody>
                {legacyTimeWorkOrders.map((workOrder, index) => {
                  const workOrderWorkTimes = dateWorkTimes
                    .filter(
                      (workTime) =>
                        workTime.employeeWorkDay.workOrderEmployee.workOrderTrip
                          .workOrderId === workOrder.id
                    )
                    .reduce(
                      (total, wt) => {
                        total.regular += wt.hours;
                        total.overtime += wt.overtimeHours;
                        return total;
                      },
                      { regular: 0, overtime: 0 }
                    );
                  return (
                    <WorkOrderRow
                      key={index}
                      workOrder={workOrder}
                      index={index}
                      workOrderWorkTimes={workOrderWorkTimes}
                    />
                  );
                })}
                {otherTimeTypes.map((otherTimeType, i) =>
                  dateData[otherTimeType] &&
                  (dateData[otherTimeType].reg !== 0 ||
                    dateData[otherTimeType].ot !== 0) ? (
                    <tr key={i}>
                      <td>Non Job Time</td>
                      <td>{otherTimeType}</td>
                      <td>{dateData[otherTimeType].reg.toFixed(2)}</td>
                      <td>{dateData[otherTimeType].ot.toFixed(2)}</td>
                    </tr>
                  ) : null
                )}
              </tbody>
            </Table>
          ) : null}
        </>
      ),
    });
  };

  const onApprovePayrollWeek = async () => {
    if (!authContext.serviceLocationId) {
      setInformationModal({
        isOpen: true,
        title: "Payroll Approval",
        body: "Please select a specific location first.",
      });
    } else {
      setConfirmationModal({
        isOpen: true,
        onSubmit: async () => {
          try {
            setConfirmationModal(initConfirmationModal);
            setPayrollApprovalLoading(true);
            await payrollWeekApprovalApi.approvePayrollWeek({
              weekStart: monday.format("YYYY-MM-DD"),
              serviceLocationId: authContext.serviceLocationId,
            });
            setPayrollApprovalLoading(false);
            setInformationModal({
              isOpen: true,
              title: "Payroll Approved",
              body: "Payroll week approved successfully.",
            });
            setAuthContext({
              ...authContext,
              serviceLocations: [],
            });
            setReloadPayrollWeekApproval(true);
          } catch (err) {
            setConfirmationModal(initConfirmationModal);
            setPayrollApprovalLoading(false);
            setInformationModal({
              isOpen: true,
              title: "Error",
              body:
                err?.response?.data[0].msg ||
                "There was an error with your request.",
            });
          }
        },
        onClose: () => {
          setConfirmationModal(initConfirmationModal);
        },
        title: "Payroll Approval",
        body: `<span class="text-center">Do you confirm you want to approve this payroll week?</span>`,
        confirmColor: "danger",
      });
    }
  };

  const onUndoPayrollWeekApproval = async () => {
    setConfirmationModal({
      isOpen: true,
      onSubmit: async () => {
        try {
          setConfirmationModal(initConfirmationModal);
          setPayrollApprovalLoading(true);
          await payrollWeekApprovalApi.undoPayrollWeekApproval({
            id: payrollWeekApproved.id,
          });
          setPayrollApprovalLoading(false);
          setInformationModal({
            isOpen: true,
            title: "Undo Payroll Approval",
            body: "Payroll week approval was successfully undone.",
          });
          setAuthContext({
            ...authContext,
            serviceLocations: [],
          });
          setReloadPayrollWeekApproval(true);
        } catch (err) {
          setConfirmationModal(initConfirmationModal);
          setPayrollApprovalLoading(false);
          setInformationModal({
            isOpen: true,
            title: "Error",
            body:
              err?.response?.data[0].msg ||
              "There was an error with your request.",
          });
        }
      },
      onClose: () => {
        setConfirmationModal(initConfirmationModal);
      },
      title: "Undo Payroll Approval",
      body: `<span class="text-center">Do you confirm you want to undo the approval of this payroll week?</span>`,
      confirmColor: "danger",
    });
  };

  const onSeeOtherDetails = (entry, isCaliforniaPayroll = false) => {
    setInformationModal({
      isOpen: true,
      title: `Other Time Details`,
      rawBody: true,
      size: "xl",
      body: (
        <Table className="border rounded mb-0">
          <thead className="small text-muted">
            <tr>
              <th>Employee</th>
              {otherTimeTypes
                .filter((type) => {
                  if (
                    !isCaliforniaPayroll &&
                    type === LUNCH_HOURS &&
                    entry.LUNCH === "0.00"
                  ) {
                    return false;
                  }
                  return type !== SHOP_HOURS;
                })
                .map((otherTimeType, index) => (
                  <th key={index}>{otherTimeType}</th>
                ))}
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{entry.employee}</td>
              {otherTimeTypes
                .filter((type) => {
                  if (
                    !isCaliforniaPayroll &&
                    type === LUNCH_HOURS &&
                    entry.LUNCH === "0.00"
                  ) {
                    return false;
                  }
                  return type !== SHOP_HOURS;
                })
                .map((otherTimeType, index) => (
                  <td key={index}>{entry[otherTimeType]}</td>
                ))}
            </tr>
          </tbody>
        </Table>
      ),
    });
  };

  const handleExportCSV = () => {
    onExportCSV(rawData, monday);
  };

  const shouldShowDoubleTime =
    rawData.length &&
    rawData.some((entry) =>
      entry.payRoll.some((payRollEntry) => payRollEntry.IS_CALIFORNIA_PAYROLL)
    );

  return (
    <Container fluid>
      <Card className="col-12 px-0">
        <CardHeader className="d-flex align-items-center justify-content-between mt-2">
          <div className="text-dark flex-grow-1 d-flex align-items-center">
            <h3 className="mb-0 ">Payroll Report</h3>
            <small className="text-muted ml-2 pt-1">({data.length})</small>
          </div>
          {payrollApprovalLoading ? (
            <div className="min-width-50">
              <Loader size="sm" />
            </div>
          ) : !payrollWeekApproved ? (
            <>
              {data?.length ? (
                !authContext.serviceLocationId ? (
                  <TooltipItem
                    id={monday}
                    title={"You must select a service location first"}
                  >
                    <Button
                      color={"success"}
                      onClick={onApprovePayrollWeek}
                      disabled={true}
                      className="mr-2 rounded"
                      size="sm"
                    >
                      Approve for Payroll
                    </Button>
                  </TooltipItem>
                ) : (
                  <Button
                    color={"success"}
                    onClick={onApprovePayrollWeek}
                    className="mr-2 rounded"
                    size="sm"
                  >
                    Approve for Payroll
                  </Button>
                )
              ) : null}
            </>
          ) : (
            <div className="d-flex justify-content-center align-items-center">
              <span className="text-muted small mr-2">
                {`Approved at `}
                {moment(payrollWeekApproved.createdAt).format("MM-DD-YYYY")}
              </span>
              <Button
                color={"danger"}
                onClick={onUndoPayrollWeekApproval}
                className="mr-2 rounded"
                size="sm"
              >
                Undo Approval
              </Button>
            </div>
          )}
          <div className="d-flex justify-content-between">
            <Button className="my-1" onClick={previousMonday}>
              <FontAwesomeIcon icon={faChevronLeft} />
            </Button>
            <strong className="my-1 mx-1 p-2 border rounded">
              {monday.format("MM/DD/YYYY")}
              {" - "}
              {getSunday().format("MM/DD/YYYY")}
            </strong>
            <Button
              className="my-1"
              onClick={nextMonday}
              disabled={getSunday().isAfter(moment())}
            >
              <FontAwesomeIcon icon={faChevronRight} />
            </Button>
          </div>
          <Button
            color={"primary"}
            onClick={handleExportCSV}
            disabled={!data?.length}
            className="ml-2 rounded"
            size="sm"
          >
            <FontAwesomeIcon icon={faFileCsv} className="mr-2" />
            Download
          </Button>
        </CardHeader>
        <CardBody className="d-flex flex-column">
          {loading ? (
            <Loader size="sm" />
          ) : (
            <Table className="mb-0">
              <thead className="small text-muted">
                <tr>
                  <th className="border-top-0">Employee</th>
                  {Array.from(Array(7).keys()).map((c, index) => (
                    <th className="border-top-0" key={index}>
                      {getDateLabel(monday, c)}
                    </th>
                  ))}
                  <th className="border-top-0">Regular</th>
                  <th className="border-top-0">Overtime</th>
                  {shouldShowDoubleTime ? (
                    <th className="border-top-0">Double Time</th>
                  ) : null}
                  <th className="border-top-0">Other</th>
                  <th className="border-top-0">Total</th>
                </tr>
              </thead>
              <tbody>
                {data.length ? (
                  data.map((entry, i) => {
                    const otherValue = otherTimeTypes
                      .filter((item) => item !== SHOP_HOURS)
                      .reduce((p, c) => p + parseFloat(entry[c]), 0);
                    const isCaliforniaPayroll = rawData.some(
                      (employee) =>
                        employee.id === entry.employeeId &&
                        employee.payRoll.some(
                          (payrollEntry) => payrollEntry.IS_CALIFORNIA_PAYROLL
                        )
                    );
                    return (
                      <tr className="border-bottom" key={i}>
                        <td className="text-truncate">
                          {entry.total === entry.totalApproved &&
                          entry.approvedBy ? (
                            <>
                              <FontAwesomeIcon
                                icon={faCircle}
                                className="mr-2"
                                size="sm"
                                color={"green"}
                                id={`TooltipApprovedStatus-${i}`}
                                onMouseEnter={() => toggleTooltip(i)}
                                onMouseLeave={() => toggleTooltip(null)}
                                onClick={() => toggleTooltip(i)}
                              />
                              <Tooltip
                                isOpen={tooltipOpen === i}
                                target={`TooltipApprovedStatus-${i}`}
                                toggle={() => toggleTooltip(null)}
                              >
                                Approved Time: {entry.totalApproved}
                                <br />
                                Approved By: {entry.approvedBy}
                              </Tooltip>
                            </>
                          ) : (
                            <>
                              <FontAwesomeIcon
                                icon={faCircle}
                                className="mr-2"
                                size="sm"
                                color={"red"}
                              />
                            </>
                          )}
                          {entry.employee}
                        </td>
                        {Array.from(Array(7).keys()).map((c, i) => {
                          const date = getDateLabel(monday, c);
                          const value = entry[date];
                          return (
                            <td
                              className={`border-top-0 ${
                                value !== "-" ? "text-link cursor-pointer" : ""
                              }`}
                              onClick={() =>
                                value !== "-"
                                  ? onSeeDateDetails(
                                      entry,
                                      moment(monday).add(c, "day")
                                    )
                                  : null
                              }
                              key={i}
                            >
                              {value}
                            </td>
                          );
                        })}
                        <td className="text-truncate">{entry.regular}</td>
                        <td className="text-truncate">{entry.overtime}</td>
                        {shouldShowDoubleTime ? (
                          <td className="text-truncate">
                            {isCaliforniaPayroll ? entry.double : "-"}
                          </td>
                        ) : null}
                        <td
                          className={`border-top-0 ${
                            otherValue > 0 ? "text-link cursor-pointer" : ""
                          }`}
                          onClick={() =>
                            otherValue > 0
                              ? onSeeOtherDetails(entry, isCaliforniaPayroll)
                              : null
                          }
                        >
                          {otherValue.toFixed(2)}
                        </td>
                        <td className="text-truncate">{entry.total}</td>
                      </tr>
                    );
                  })
                ) : (
                  <tr>
                    <td colSpan={13} className="text-center text-muted small">
                      No data has been recorded
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>
          )}
        </CardBody>
      </Card>
      {confirmationModal.isOpen ? (
        <ConfirmationModal {...confirmationModal} />
      ) : informationModal.isOpen ? (
        <InformationModal
          title={informationModal.title}
          body={informationModal.body}
          rawBody={informationModal.rawBody}
          size={informationModal.size}
          onClose={() =>
            setInformationModal({ isOpen: false, title: "", body: "" })
          }
        />
      ) : null}
    </Container>
  );
};

export default PayrollReport;
